import { persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
// Import reducers
import userReducer from './reducer/userReducer';
import profileReducer from './reducer/profileReducer';
import customizationReducer from './reducer/customizationReducer';
import snackbarReducer from './reducer/snackbarReducer';
import clientReducer from './reducer/clientReducer';
import employeeReducer from './reducer/employeeReducer';
import vendorReducer from './reducer/vendorReducer';
import productDetailsReducer from './reducer/productDetailsReducer';
import editProductReducer from './reducer/editProductReducer';
import transportReducer from './reducer/transportReducer';

export const store = configureStore({
  reducer: {
    auth: userReducer,
    profile: profileReducer,
    customization: customizationReducer,
    snackbar: snackbarReducer,
    client: clientReducer,
    employee: employeeReducer,
    vendor: vendorReducer,
    productDetails: productDetailsReducer,
    editProduct: editProductReducer,
    transport: transportReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persister = persistStore(store);

import { createTheme } from '@mui/material/styles';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';
import theme1 from '../assets/scss/_theme1.module.scss';
import theme2 from '../assets/scss/_theme2.module.scss';
import theme3 from '../assets/scss/_theme3.module.scss';
import theme4 from '../assets/scss/_theme4.module.scss';
import theme5 from '../assets/scss/_theme5.module.scss';
import theme6 from '../assets/scss/_theme6.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import customShadows from './shadows';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export const theme = (customization) => {
  let color;

  // Determine the theme color set based on customization
  switch (customization.presetColor) {
    case 'theme1':
      color = theme1;
      break;
    case 'theme2':
      color = theme2;
      break;
    case 'theme3':
      color = theme3;
      break;
    case 'theme4':
      color = theme4;
      break;
    case 'theme5':
      color = theme5;
      break;
    case 'theme6':
      color = theme6;
      break;
    case 'default':
    default:
      color = colors;
  }

  // Clone the theme options to avoid mutation of the original state
  const themeOption = {
    colors: color,
    heading: '',
    paper: '',
    backgroundDefault: '',
    background: '',
    darkTextPrimary: '',
    darkTextSecondary: '',
    textDark: '',
    menuSelected: '',
    menuSelectedBack: '',
    divider: '',
    customization, // Include customization object (do not mutate)
  };

  // Create a fresh themeOption object, without mutating the original.
  const updatedThemeOption = { ...themeOption };

  // Modify the theme option based on navType
  switch (customization.navType) {
    case 'dark':
      updatedThemeOption.paper = color.darkLevel2;
      updatedThemeOption.backgroundDefault = color.darkPaper;
      updatedThemeOption.background = color.darkBackground;
      updatedThemeOption.darkTextPrimary = color.darkTextPrimary;
      updatedThemeOption.darkTextSecondary = color.darkTextSecondary;
      updatedThemeOption.textDark = color.darkTextPrimary;
      updatedThemeOption.menuSelected = color.darkSecondaryMain;
      updatedThemeOption.menuSelectedBack = color.darkSecondaryMain + 15;
      updatedThemeOption.divider = color.darkTextPrimary;
      updatedThemeOption.heading = color.darkTextTitle;
      break;
    case 'light':
    default:
      updatedThemeOption.paper = color.paper;
      updatedThemeOption.backgroundDefault = color.paper;
      updatedThemeOption.background = color.primaryLight;
      updatedThemeOption.darkTextPrimary = color.grey700;
      updatedThemeOption.darkTextSecondary = color.grey500;
      updatedThemeOption.textDark = color.grey900;
      updatedThemeOption.menuSelected = color.secondaryDark;
      updatedThemeOption.menuSelectedBack = color.secondaryLight;
      updatedThemeOption.divider = color.grey200;
      updatedThemeOption.heading = color.grey900;
      break;
  }

  // Create the final theme options object
  const themeOptions = {
    direction: customization.rtlLayout ? 'rtl' : 'ltr',
    palette: themePalette(updatedThemeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    typography: themeTypography(updatedThemeOption),
    customShadows: customShadows(customization.navType, updatedThemeOption),
  };

  // Create the theme using Material-UI's createTheme function
  const themes = createTheme(themeOptions);

  // Override components styles
  themes.components = componentStyleOverrides(updatedThemeOption);

  return themes; // Return the theme object (fresh, non-mutated)
};

export default theme;

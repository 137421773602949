import ProductionList from '../container/production/ProductionList';
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('../views/dashboard/Default/Dashboard'))
);
const DashboardAnalytics = Loadable(
  lazy(() => import('../views/dashboard/Analytics'))
);

// Product Routing
const ProductStock = Loadable(
  lazy(() => import('../container/inventory/product/ProductStock'))
);
const ProductDetails = Loadable(
  lazy(() => import('../container/inventory/product/ProductDetails'))
);

// Employee Routing
const EmployeeList = Loadable(
  lazy(() => import('../container/hrms/employee/EmployeeList'))
);

const EmployeeDetail = Loadable(
  lazy(() => import('../container/hrms/employee/EmployeeDetail'))
);

// Client Routing
const ClientList = Loadable(
  lazy(() => import('../container/hrms/client/ClientList'))
);

const ClientDetail = Loadable(
  lazy(() => import('../container/hrms/client/ClientDetail'))
);

// Vendor Routing
const VendorList = Loadable(
  lazy(() => import('../container/hrms/vendor/VendorList'))
);
const VendorDetail = Loadable(
  lazy(() => import('../container/hrms/vendor/VendorDetail'))
);

// Material Routing
const MaterialStock = Loadable(
  lazy(() => import('../container/inventory/material/MaterialStock'))
);
const AddEditMaterial = Loadable(
  lazy(() => import('../container/inventory/material/AddEditMaterial'))
);

// Accounting Routing
const ExpenseList = Loadable(
  lazy(() => import('../container/account/expense/ExpenseList'))
);

const AddEditExpense = Loadable(
  lazy(() => import('../container/account/expense/AddEditExpense'))
);

// Bills
const ProductPurchaseBill = Loadable(
  lazy(() => import('../container/account/purchase-bill/ProductPurchaseBill'))
);
const SaleProductList = Loadable(
  lazy(() => import('../container/account/sales-bill/SaleProductList'))
);

// Salary
const SalaryCalculator = Loadable(
  lazy(() => import('../container/salary/salary/SalaryCalculator'))
);

// Advance
const AdvanceAmount = Loadable(
  lazy(() => import('../container/salary/advance/AdvanceAmount'))
);

// const ProductPurchasing = Loadable(
//   lazy(() => import('../container/account/purchase-bill/'))
// );

// Order Routing
const OrderTrack = Loadable(
  lazy(() => import('../container/order/OrderTrack'))
);

//

// Admin -->
const ProductCategory = Loadable(
  lazy(() => import('../container/admin/productCategory/ProductCategory'))
);

const MaterialCategory = Loadable(
  lazy(() => import('../container/admin/materialCategory/MaterialCategory'))
);

const Designation = Loadable(
  lazy(() => import('../container/admin/designation/Designation'))
);

const ProductionStatus = Loadable(
  lazy(() => import('../container/admin/productionStatus/ProductionStatus'))
);

const MaterialConsumption = Loadable(
  lazy(() => import('../container/inventory/material/MaterialConsumptionList'))
);

// Task
const PreviousTask = Loadable(
  lazy(() => import('../container/task/PreviousTask'))
);

// transport
const Transport = Loadable(
  lazy(() => import('../container/transport/Transport'))
);

// ==============================|| MAIN ROUTING ||============================== //

const PrivateRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    // Order Module
    {
      path: '/app/order-list',
      element: <OrderTrack />,
    },
    {
      path: '/app/production-list',
      element: <ProductionList />,
    },

    // Bill
    // Purchase Bill
    {
      path: '/app/purchase-product/list',
      element: <ProductPurchaseBill />,
    },
    {
      path: '/app/sale-bill/list',
      element: <SaleProductList />,
    },

    // Expense
    {
      path: '/app/expense-list',
      element: <ExpenseList />,
    },

    {
      path: '/app/add-edit-expense',
      element: <AddEditExpense />,
    },

    // Salary
    {
      path: '/app/salary-calculator',
      element: <SalaryCalculator />,
    },

    // Advance
    {
      path: '/app/advance/advance-take',
      element: <AdvanceAmount />,
    },

    // {
    //   path: '/app/productpurchasing',
    //   element: <ProductPurchasing />,
    // },

    // Client Routes
    {
      path: '/app/client-list',
      element: <ClientList />,
    },
    {
      path: '/app/client-detail/:id',
      element: <ClientDetail />,
    },

    // Employee Routes
    {
      path: '/app/employee-list',
      element: <EmployeeList />,
    },
    {
      path: '/app/employee-detail/:id',
      element: <EmployeeDetail />,
    },

    // Vendor Routes
    {
      path: '/app/vendor-list',
      element: <VendorList />,
    },

    {
      path: '/app/vendor-detail/:id',
      element: <VendorDetail />,
    },
    // Product Stock
    {
      path: '/app/products',
      element: <ProductStock />,
    },
    {
      path: '/product/product-details/:id',
      element: <ProductDetails />,
    },
    // Material Routes
    {
      path: '/app/material',
      element: <MaterialStock />,
    },
    {
      path: '/app/add-material',
      element: <AddEditMaterial />,
    },
    {
      path: '/dashboard/default',
      element: <DashboardDefault />,
    },
    {
      path: '/dashboard/analytics',
      element: <DashboardAnalytics />,
    },

    // Admin-Routes
    {
      path: '/app/product-category-list',
      element: <ProductCategory />,
    },

    {
      path: '/app/material-category-list',
      element: <MaterialCategory />,
    },

    {
      path: '/app/designation-list',
      element: <Designation />,
    },

    {
      path: '/app/production-status-list',
      element: <ProductionStatus />,
    },

    {
      path: '/material/consumption/list',
      element: <MaterialConsumption />,
    },

    {
      path: '/app/task',
      element: <PreviousTask />,
    },

    {
      path: '/app/transport-status',
      element: <Transport />,
    },
  ],
};

export default PrivateRoutes;

import { useState, useEffect } from 'react';
import { CardMedia, CircularProgress, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getProductByUrl } from '../utils/AwsUtil'; // Ensure the path is correct

const FileViewer = ({ productUrl, name, height, to }) => {
  const [s3FilePath, setS3FilePath] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const url = await getProductByUrl(productUrl);
        setS3FilePath(url);
      } catch (err) {
        console.error('Error fetching file:', err);
        setError('Failed to load image');
      } finally {
        setLoading(false);
      }
    };

    fetchFile();
  }, [productUrl]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color='error'>{error}</Typography>;

  return (
    <CardMedia
      component={Link}
      to={to}
      image={s3FilePath}
      title={name}
      sx={{ height }}
    />
  );
};

FileViewer.propTypes = {
  productUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
};

export default FileViewer;

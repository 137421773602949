// src/utils/AwsUtil.js
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl as getPresignedUrl } from '@aws-sdk/s3-request-presigner';
import {
  AWS_ACCESS_ID,
  AWS_ACCESS_KEY,
  AWS_BUCKET_NAME,
  AWS_REGION,
} from '../backend';

/**
 * Fetch a signed URL for a product from S3 using its URL.
 *
 * @param {string} productUrl - The URL of the product to fetch.
 * @returns {Promise<string>} - A promise that resolves with the signed URL.
 */
export const getProductByUrl = async (productUrl) => {
  const s3 = new S3Client({
    region: AWS_REGION,
    credentials: {
      accessKeyId: AWS_ACCESS_ID,
      secretAccessKey: AWS_ACCESS_KEY,
    },
  });

  const command = new GetObjectCommand({
    Bucket: AWS_BUCKET_NAME,
    Key: productUrl,
  });

  try {
    const signedUrl = await getPresignedUrl(s3, command, { expiresIn: 3600 }); // URL expires in 1 hour
    return signedUrl;
  } catch (error) {
    throw new Error(`Failed to get signed URL: ${error.message}`);
  }
};

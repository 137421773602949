import FileViewer from '../../utils/FileViewer';

export const ProductionColumns = [
  {
    Header: 'Id',
    accessor: 'id',
    show: false,
  },
  {
    Header: 'Client Name',
    accessor: 'clientName',
  },
  {
    Header: 'Image',
    accessor: 'productImageLink',
    Cell: ({ value }) => {
      return <FileViewer productUrl={value} width={'96'} height={'65'} />;
    },
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Order Date',
    accessor: 'orderDate',
  },
  {
    Header: 'Delivery Date',
    accessor: 'deliveryDate',
  },
  {
    Header: 'Status',
    accessor: 'statusValue',
  },
];

import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './routes/index';
import themes from './themes'; // Assuming themes is a function
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
import Snackbar from './ui-component/extended/Snackbar';

const App = () => {
  const customization = useSelector((state) => state.customization);

  // Ensure that themes(customization) returns a fresh theme object
  const theme = themes(customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Locales>
          <NavigationScroll>
            <Routes />
            <Snackbar />
          </NavigationScroll>
        </Locales>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

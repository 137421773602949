import { Button, DialogContent, Grid, Stack } from '@mui/material';
import FormikControl from '../../component/formik/FormikControl';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import * as yup from 'yup';
import { updateOrderStatus } from '../../service/ProductionService';
import { useCallback, useEffect, useState } from 'react';
import { getAllProductionStatusKeyValue } from '../../service/AdminService';
import { SNACKBAR_OPEN } from '../../redux-toolkit/reducer/snackbarReducer';

const UpdateProductionStatus = ({ onCancel, formValues }) => {
  const dispatch = useDispatch();

  const orderId = formValues.id;

  const initialValues = {
    status: '',
  };

  const [productionStatus, setProductionStatus] = useState([]);

  const onSubmit = (values) => {
    updateOrderStatus({ ...values }, orderId).then((data) => {
      console.log('Response ==>  ', data);
      if (data.error) {
        alert('Error ', data.reason);
      } else {
        onCancel();
        dispatch(
          SNACKBAR_OPEN({
            open: true,
            message: 'UPDATE PRODUCTION STAGE SUCCESSFULLY',
            variant: 'alert',
            alertSeverity: 'success',
          })
        );
      }
    });
  };

  const validationSchema = yup.object({});

  const loadAllProductionStages = useCallback(async () => {
    await getAllProductionStatusKeyValue().then((data) => {
      if (data) {
        setProductionStatus(data);
      }
    });
  }, []);

  useEffect(() => {
    loadAllProductionStages();
  }, [loadAllProductionStages]);

  return (
    <Grid container spacing={gridSpacing} justifyContent='flex-start'>
      <Grid item xs={12} md={12} lg={12}>
        <DialogContent sx={{ p: 3 }}>
          <MainCard title={'Update Status'}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize>
              {(formik) => {
                return (
                  <Form autoComplete='off'>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} sm={5}>
                        <FormikControl
                          control='select'
                          label='Production Status'
                          name='status'
                          options={productionStatus}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction='row' justifyContent='flex-end'>
                          <AnimateButton>
                            <Button variant='contained' type='submit'>
                              Update
                            </Button>
                          </AnimateButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </MainCard>
        </DialogContent>
      </Grid>
    </Grid>
  );
};

export default UpdateProductionStatus;

export const url = {
  profile: {
    CREATE_PROFILE_URL: `/api/v1/public/profile/create`,
    GET_ALL_PROFILE_URL: `/api/v1/admin/profile/list`,
    GET_MONTH_PROFILE_LIST_URL: `/api/v1/admin/profile/month`,
    GET_PROFILE_URL: `/api/v1/profile/`,
    DELETE_PROFILE_URL: `/api/v1/profile/delete/`,
    UPDATE_PROFILE_URL: `/api/v1/profile/update/`,
  },
  user: {
    LOGIN_URL: `/api/v1/public/user/login`,
    GET_PASSCODE_URL: `/api/v1/public/user/get-passcode`,
    FORGET_PASSWORD_URL: `/api/v1/public/user/forget-password`,
    VERIFY_CODE_URL: `/api/v1/public/user/verify-code`,
    RESET_PASSWORD_URL: `/api/v1/public/user/reset-password`,
    CREATE_USER_URL: `/api/v1/user/create`,
    GET_ALL_USER_URL: `/api/v1/user/list`,
    CHANGE_PASSWORD_URL: `/api/v1/user/change-password/`,
    CHECK_PASSWORD_URL: `/api/v1/user/check-password/`,
    GET_USER_URL: `/api/v1/user/`,
    DELETE_USER_URL: `/api/v1/user/delete/`,
    UPDATE_USER_URL: `/api/v1/user/update/`,
  },
  client: {
    ADD_CLIENT_URL: `/api/v1/client/add`,
    UPDATE_CLIENT_URL: `/api/v1/client/update/`,
    DELETE_CLIENT_URL: `/api/v1/client/delete/`,
    GET_CLIENT_URL: `/api/v1/client/`,
    GET_ALL_CLIENT_URL: `/api/v1/client/list/`,
    GET_MONTH_CLIENT_LIST_URL: `/api/v1/client/date/`,
    GET_ALL_CLIENT_KEY_VALUE_URL: `/api/v1/client/list/key-value/`,
  },
  employee: {
    ADD_EMPLOYEE_URL: `/api/v1/employee/add`,
    UPDATE_EMPLOYEE_URL: `/api/v1/employee/update/`,
    DELETE_EMPLOYEE_URL: `/api/v1/employee/delete/`,
    GET_EMPLOYEE_URL: `/api/v1/employee/`,
    GET_ALL_EMPLOYEE_URL: `/api/v1/employee/list/`,
    GET_MONTH_EMPLOYEE_LIST_URL: `/api/v1/employee/date/`,
    GET_ALL_EMPLOYEE_KEY_VALUE_URL: `/api/v1/employee/list/key-value/`,
    GET_ALL_EMPLOYEE_KEY_VALUE_DESIGNATION_URL: `/api/v1/employee/list/key-value/designation/`,
    GET_ALL_EMPLOYEE_KEY_VALUE_PAID_URL: `/api/v1/employee/list/key-value/paid-by/`,
  },
  product: {
    ADD_PRODUCT_URL: `/api/v1/product/add`,
    UPDATE_PRODUCT_URL: `/api/v1/product/update/`,
    UPDATE_PRODUCT_IMAGE_URL: `/api/v1/product/update/image/`,
    DELETE_PRODUCT_URL: `/api/v1/product/delete/`,
    GET_PRODUCT_URL: `/api/v1/product/`,
    GET_ALL_PRODUCT_URL: `/api/v1/product/list/`,
    UPDATE_PRODUCT_QUANTITY_URL: `/api/v1/product-stock/update/stock/`,
  },
  material: {
    ADD_MATERIAL_URL: `/api/v1/material/add`,
    UPDATE_MATERIAL_URL: `/api/v1/material/update/`,
    UPDATE_MATERIAL_STOCK_URL: `/api/v1/material/update/stock/`,
    DELETE_MATERIAL_URL: `/api/v1/material/delete/`,
    GET_MATERIAL_URL: `/api/v1/material/`,
    GET_ALL_MATERIAL_URL: `/api/v1/material/list/`,
  },
  vendor: {
    ADD_VENDOR_URL: `/api/v1/vendor/add`,
    UPDATE_VENDOR_URL: `/api/v1/vendor/update/`,
    DELETE_VENDOR_URL: `/api/v1/vendor/delete/`,
    GET_VENDOR_URL: `/api/v1/vendor/`,
    GET_ALL_VENDOR_URL: `/api/v1/vendor/list/`,
    GET_MONTH_VENDOR_LIST_URL: `/api/v1/vendor/date/`,
    GET_ALL_VENDOR_KEY_VALUE_URL: `/api/v1/vendor/list/key-value/`,
  },
  expense: {
    ADD_EXPENSE_URL: `/api/v1/expense/add`,
    UPDATE_EXPENSE_URL: `/api/v1/expense/update/`,
    DELETE_EXPENSE_URL: `/api/v1/expense/delete/`,
    GET_EXPENSE_URL: `/api/v1/expense/`,
    GET_ALL_EXPENSE_URL: `/api/v1/expense/list/`,
    GET_MONTH_EXPENSE_LIST_URL: `/api/v1/expense/date/`,
  },
  order: {
    ADD_ORDER_URL: `/api/v1/order/add`,
    UPDATE_ORDER_URL: `/api/v1/order/update/`,
    DELETE_ORDER_URL: `/api/v1/order/delete/`,
    GET_ORDER_URL: `/api/v1/order/`,
    GET_ALL_ORDER_URL: `/api/v1/order/list/`,
    GET_MONTH_ORDER_LIST_URL: `/api/v1/order/date/`,
  },
  production: {
    UPDATE_PRODUCTION_URL: `/api/v1/production/update/status/`,
    GET_ALL_PRODUCTION_URL: `/api/v1/production/list/`,
  },
  salary: {
    ADD_SALARY_URL: `/api/v1/salary/paid`,
    GET_ALL_SALARY_URL: `/api/v1/salary/list/`,
  },
  advance_salary: {
    ADD_ADVANCE_SALARY_URL: `/api/v1/advance/paid-advance`,
    GET_ALL_ADVANCE_SALARY_URL: `/api/v1/advance/list/`,
  },
  purchase_product: {
    ADD_PURCHASE_PRODUCT_URL: `/api/v1/purchase-product/add`,
    GET_ALL_PURCHASE_PRODUCT_URL: `/api/v1/purchase-product/list/`,
    GET_PURCHASE_PRODUCT_URL: `/api/v1/purchase-product/`,
  },
  sell_product: {
    ADD_ADVANCE_SALARY_URL: `/api/v1/purchase-product/add`,
    GET_ALL_PURCHASE_PRODUCT_URL: `/api/v1/purchase-product/list/`,
    GET_PURCHASE_PRODUCT_URL: `/api/v1/purchase-product/`,
  },
  material_used: {
    ADD_MATERIAL_USED_IN_PRODUCT_URL: `/api/v1/material_used/add`,
    GET_MATERIAL_USED_IN_PRODUCT_URL: `/api/v1/material_used/`,
    UPDATE_MATERIAL_USED_IN_PRODUCT_URL: `/api/v1/material_used/update/`,
    GET_MATERIAL_DATA_BY_PRODUCT_ID_URL: 'api/v1/material_used/product/',
  },

  category: {
    product_category: {
      ADD_PRODUCT_CATEGORY_URL: `/api/v1/product/category`,
      UPDATE_PRODUCT_CATEGORY_URL: `/api/v1/product/category/update/`,
      GET_ALL_PRODUCT_CATEGORY_URL: `/api/v1/product/category/list/`,
      GET_ALL_PRODUCT_CATEGORY_KEY_VALUE_URL: `/api/v1/product/category/list/key-value/`,
      DELETE_PRODUCT_CATEGORY_URL: `/api/v1/product/category/delete/`,
    },
    material_category: {
      ADD_MATERIAL_CATEGORY_URL: `/api/v1/material/category`,
      UPDATE_MATERIAL_CATEGORY_URL: `/api/v1/material/category/update/`,
      GET_ALL_MATERIAL_CATEGORY_URL: `/api/v1/material/category/list/`,
      GET_ALL_MATERIAL_CATEGORY_KEY_VALUE_URL: `/api/v1/material/category/list/key-value/`,
      DELETE_MATERIAL_CATEGORY_URL: `/api/v1/material/category/delete/`,
    },
    designation: {
      ADD_DESIGNATION_URL: `/api/v1/employee/designation`,
      GET_ALL_DESIGNATION_URL: `/api/v1/employee/designation/list/`,
      GET_ALL_DESIGNATION_KEY_VALUE_URL: `/api/v1/employee/designation/list/key-value/`,
      UPDATE_DESIGNATION_URL: `/api/v1/employee/designation/update/`,
      DELETE_DESIGNATION_URL: `/api/v1/employee/designation/delete/`,
    },
    production_status: {
      ADD_PRODUCTION_STATUS_URL: `/api/v1/production/stage`,
      GET_ALL_PRODUCTION_STATUS_URL: `/api/v1/production/stage/list/`,
      GET_ALL_PRODUCTION_STATUS_KEY_VALUE_URL: `/api/v1/production/stage/list/key-value/`,
      UPDATE_PRODUCTION_STATUS_CATEGORY_URL: `/api/v1/production/stage/update/`,
      DELETE_PRODUCTION_STATUS_URL: `/api/v1/production/stage/delete/`,
    },
  },

  consumption: {
    ADD_MATERIAL_CONSUMPTION_URL: `/api/v1/material/consume`,
    GET_ALL_MATERIAL_CONSUMPTION_URL: `/api/v1/material/consume/list/`,
  },

  task: {
    ADD_DAILY_TASK_URL: '/api/v1/task/add',
    UPDATE_DAILY_TASK_URL: '/api/v1/task/update/',
    GET_ALL_DAILY_TASK_URL: '/api/v1/task/list/',
    GET_ALL_DAILY_TASK_BY_STATUS_URL: '/api/v1/task/list/',
  },
  transport: {
    ADD_TODAY_TRANSPORT_TASK_URL: '/api/v1/transport/add',
    UPDATE_TRANSPORT_DAILY_TASK_URL: '/api/v1/transport/update/',
    GET_ALL_TRANSPORT_DAILY_TASK_URL: '/api/v1/transport/list/',
  },
};

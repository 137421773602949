import dashboard from './dashboard';
import application from './application';
import accounting from './accounting';
import admin from './admin';
import task from './task';
import transport from './transportation';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, application, task, transport, accounting, admin],
};

export default menuItems;
